import {mapGetters, mapActions} from 'vuex';

const _ = require('lodash')

export default {
    props: ['popupType'],
    data() {
        return {
            form: {
                buy_quantity: null,
                buy_price: null,
                buy_price_usd: null,
                less_quantity: null
            },
            category_id: null,
            product_id: null,
            product_price: null,
            categoryList: null,
            productList: [],
            productPagination: {
                current_page: 1,
                last_page: 1,
            },
            searchProductValue: '',
            searchPress: false,
        }
    },
    computed: {
        ...mapGetters({
            products: 'catalog/products',
            product: 'catalog/product',
            productCategories: 'catalog/productCategories'
        }),
    },
    watch: {
        productCategories(e){
            this.categoryList = _.clone(e.data);
            this.categoryList.map(item=>{
                if(item.children.length){
                    item.children.map(childMap=>{
                        this.categoryList.push(childMap);
                    })
                }
            })
        },
        category_id(e) {
           this.getProductsByCategory(e)
        },
        product_id(e) {
            this.showProduct(e)
        },
        product(e) {
            this.form.buy_price = Number(e.discount_price).toFixed(2);
            this.form.buy_price_usd = Number(e.current_buy_price_in_usd).toFixed(2);
            this.product_id = e.id
            this.product_price = Number(e.sale_price).toFixed(2)
        }
    },
    created() {
        this.getProductCategories()
        this.getProductListWithPagination().then(res=> {
            if(res.status){
                this.productPagination.current_page = res.data.models.current_page;
                this.productPagination.last_page = res.data.models.last_page;
                this.productList = res.data.models.data
            }
        })
    },
    methods: {
        createProduct() {
            if(Number(this.form.buy_quantity) > 0){
                const payload = {
                    buy_price_usd: this.form.buy_price_usd,
                    buy_price: this.form.buy_price,
                    buy_quantity: +this.form.buy_quantity,
                    less_quantity: +this.product.less_quantity,
                    minimum: this.product.minimum,
                    product: {
                        id: this.product.id,
                        sku: this.product.sku,
                        isbn: this.product.isbn,
                        title: this.product.title,
                    },
                    product_id: this.product.id,
                    newProduct: true,
                }
                this.$emit('createProduct', payload)
            }else{
                this.$toasted.error('Кількість має бути білье 0', {
                    duration: 3000
                })
            }
        },
        searchProduct(value){
            if(this.searchPress){
                this.searchProductValue = value;
                this.getProductListWithPagination({search: this.searchProductValue}).then(res=>{
                    if(res.status){ 
                        this.productPagination.current_page = res.data.models.current_page;
                        this.productPagination.last_page = res.data.models.last_page;
                        this.productList = res.data.models.data
                    }
                })
            }
        },
        loadMoreProduct(){
            const payload = {};
            payload.page = this.productPagination.current_page +1
            if(this.searchProductValue.length >= 3){
                payload.serach = this.searchProductValue;
            }
            this.getProductListWithPagination(payload).then(res=>{
                if(res.status){
                    this.productPagination.current_page = res.data.models.current_page;
                    this.productPagination.last_page = res.data.models.last_page;
                    this.productList = [...this.productList, ...res.data.models.data]
                }
            })
        },
        closePopup() {
            this.$emit('closePopup')
        },
        ...mapActions({
            getProductCategories: 'catalog/getProductCategories',
            getProductsByCategory: 'catalog/getProductsByCategory',
            getProductListWithPagination: 'catalog/getProductListWithPagination',
            showProduct: 'catalog/showProduct'
        }),
        // ...mapMutations({
        //     changeLot: 'lot/changeLot'
        // }),
    },
    destroyed() {
        // this.changeLot(null);
    }
}