import { mapGetters, mapMutations, mapActions} from 'vuex';
import * as moment from 'moment/moment'
import datetime from 'vuejs-datetimepicker'
import lotProductPopup from './lot-product/index.vue'
import removeConfirm from '@/components/popups/remove-confirm/index.vue'
import writeOffConfirm from '@/components/popups/writeOffConfirm/index.vue'


export default {
    props: ['popupType'],
    components: {
        datetime,
        lotProductPopup,
        removeConfirm,
        writeOffConfirm,
    },
    data() {
        return {
            form: {
                provisioner:  null,
                date: new Date(),
                warehouse: null,
                products: []
            },
            showLotPopup: false,
            showRemoveConfirm: false,
            showWriteOffConfirm: false,
            selectedItem: null,
            fields: [
                {
                    key: 'product.id',
                    label: 'id',
                },
                {
                    key: 'product.title',
                    label: 'Товар',
                },
                {
                    key: 'buy_price',
                    label: 'Ціна грн',
                },
                {
                    key: 'buy_price_usd',
                    label: 'Ціна usd',
                },
                {
                    key: 'buy_quantity',
                    label: 'Кількість',
                },
                {
                    key: 'less_quantity',
                    label: 'Залишок',
                },
                {
                    key: 'total',
                    label: 'Всього грн',
                },
                {
                    key: 'total_usd',
                    label: 'Всього usd',
                },
                {
                    key: 'newProduct',
                    label: '',
                },
            ],
            removeItem: null,
        }
    },
    computed: {
        ...mapGetters({
            lot: 'lot/lot',
            warehouses: 'warehouse/warehouses',
            provisioners: 'warehouse/provisioners',
            products: 'catalog/products',
            product: 'catalog/product'
        }),
        items() {
            return this.form.products.map( el => {
                return {
                    ...el,
                    total: +el.buy_price*el.buy_quantity,
                    total_usd: +el.buy_price_usd*el.buy_quantity,
                }
            })
        }
    },
    created() {
      this.getWarehouses()
      this.getProvisioners()
      this.getProducts()
    },
    watch:{
        lot(e){
                this.form.provisioner = e.provisioner_id;
                this.form.warehouse = e.warehouse_id;
                this.form.date = moment(e.updated_at ? e.updated_at : e.created_at).format('YYYY-MM-DD HH:mm');
                this.form.products = e.lot_products;
                // console.log(this.form.products)
        },
        item(e) {
            this.showProduct(e)
        },
    },
    methods: {
        changeProductItem(){
          this.addProductClick();
        },
        prepareRemoveItem(item){
            this.removeItem = item;
          this.showRemoveConfirm = true
        },
        appendProduct(payload) {
            console.log('payload - ', payload);
            this.form.products.push(payload)
            this.showLotPopup = false
        },
        addProductClick() {
            this.showLotPopup = true;
        },
        closePopup() {
            this.$emit('closePopup')
        },
        sendLot() {
            const data = {
                provisioner_id: this.form.provisioner,
                warehouse_id: this.form.warehouse,
                lot_products: this.form.products.map( item => {
                    return {
                        product_id: item.product_id,
                        buy_price : +item.buy_price,
                        buy_price_usd : +item.buy_price_usd,
                        buy_quantity: +item.buy_quantity,
                        less_quantity: +item.less_quantity,
                        minimum: +item.minimum,
                        newProduct: item.newProduct ? item.newProduct : false
                    }
                })
            }
            if(this.lot){
                const payload = {
                    id: this.lot.id,
                    data: data
                }
                this.$emit('changeLot', payload)
            }else{
                this.$emit('sendLot', data)
            }
        },
        selectItem(item) {
            this.selectedItem = item[0]

        },
        deleteLotProduct() {
            const index = this.items.indexOf(this.removeItem);
            this.items.splice(index, 1);
            this.form.products.splice(index, 1);
            this.showRemoveConfirm = false;
        },
        confirmWriteOff(form){
            this.showWriteOffConfirm = false
            const payload = {
                lot_id: this.selectedItem.lot_id,
                product_id: this.selectedItem.product_id,
                quantity: form.quantity,
                reason: form.reason,
            }
            this.sendWriteOff(payload).then(()=>{
                this.showLot(this.selectedItem.lot_id);
            });
        },
        ...mapActions({
            getWarehouses: 'warehouse/getWarehouses' ,
            getProvisioners: 'warehouse/getProvisioners',
            getProducts: 'catalog/getProducts',
            showProduct: 'catalog/showProduct',
            removeLotProduct: 'lot/removeLotProduct',
            sendWriteOff: 'lot/sendWriteOff',
            showLot: 'lot/showLot',

        }),
        ...mapMutations({
            changeLot: 'lot/changeLot',
            changeProduct: 'catalog/changeProduct'
        })
    },
    destroyed() {
        this.changeLot(null);
        this.changeProduct(null)
    }
}
